*{
        font-family: "Inknut Antiqua", serif;
        font-weight: 600;
        font-style: normal;
        font-size: 12.5px;
      
}

:root {
    --light-blue: #779cc0;
    --dark-blue: #55708a;
    --pure-white: #ffffff;
    --white: #f0f0f0;
    --grey: #ddd;
    --light-grey: #f9f9f9;
    --red: #9c1f1f;

}



/*===================================== ORG ADMIN TSX UI STARTS HERE ====================================*/
.org-admin-nav {
    background-color: var(--dark-blue);
    padding: 7px;
    position: fixed;
    width: 100%;
    margin-top: 0;
    z-index: 999999;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.org-admin-nav ul {
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0;
    padding: 0;
}

.org-admin-nav li {
    display: flex;
    align-items: center;
    font-size: 1.2rem;
    font-weight: bold;
    margin: 0 10px;
}

.icon-container {
    display: flex;
    align-items: center;
}

.org-admin-nav .icon svg {
    width: 24px;
    height: 24px;
    margin-left: 10px;
    fill: white;
}

.icon-cyberchat {
                                                            
    height: 40px;
    width: 220px;
}

.icon-real-cyberchat {
    height: 40px;
    width: 40px;
    margin-right: 10px;
}   


.orgadmin-nav-side-container {
    position: fixed;
    top: 27%;
    margin-left: 10px;
    color: var(--light-blue);
    text-decoration: underline;
}

.orgadmin-body-container {
    display: flex;
    flex-direction: column;
    margin-left: 150px;
    margin-right: 150px;
    width: 70%;
    padding-left: 50px;
    padding-right: 50px;
    padding-bottom: 50px;
    min-height: calc(100vh - 50px);
    background-color: var(--white);
}
.orgadmin-heading {
    align-self: center;
    color: var(--dark-blue);
    padding-top: 50px;
    font-size: 1.5rem;
    font-weight: bold;
    
}
b{
    font-weight: bold;
}

.orgadmin-plans-container {
    display: flex;
    justify-content: space-evenly;
    margin-top: 20px;
}
.orgadmin-plan {
    min-width: 230px;
    margin: 20px;
    padding: 20px;
    border-radius: 30px;
    background-color: var(--light-blue);
    border: 2px solid var(--dark-blue);
    color: white;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5); /* Increase opacity for a darker shadow */
}

.orgadmin-plan-button {
    width: 30%;
    color: var(--pure-white);
    background-color: var(--light-blue);
    border: 3px solid var(--dark-blue);
    border-radius: 10px;
    padding: 7.5px;
    margin: 15px;
}

.orgadmin-plan-button:hover {
    background-color: var(--dark-blue);
    border: 3px solid var(--light-blue);
    cursor: pointer;
    transform: scale(1.05);
}

.orgadmin-plan-button-container {
    display: flex;
    justify-content: center;
}

.orgadmin-plan:hover {
    transform: scale(1.05);
    cursor: pointer;

}

.orgadmin-plan ul li {
    list-style:circle;
    text-decoration: none;
}

.orgadmin-plan ul li, .orgadmin-plan p {
    color: white;
    font-size: 0.8rem;
}
.orgadmin-plan p b, .orgadmin-plan ul li b {
    font-weight: bold;
    font-size: 0.9rem;
}

.orgadmin-plan p {
    margin: 0;
}

.orgadmin-plan-dark {
    background-color: var(--dark-blue);
    border: 2px solid white;
    color: white;
}

.orgadmin-subheading {
    color: var(--dark-blue);
    font-size: 1.2rem;
    font-weight: bold;
    margin-top: 20px;
    text-decoration: underline;
}

.orgadmin-paragraph {
    color: var(--light-blue);
    font-size: 1rem;
    margin-top: 20px;
}

option {
    background-color: var(--light-blue);
    color: white;
}

option:hover {
    background-color: white;
    color: var(--light-blue);
}

.orgadmin-select {
    width: 100%;
    border-radius: 10px;
    border: solid var(--light-blue) 3px;
   
    
    padding: 10px;
    color: var(--light-blue);
    margin-bottom: 30px;
    font-weight: bold;
}

label {
    font-weight: bold;
    color: var(--light-blue);
}
input {
    width: 100%;
    border-radius: 10px;
    border: solid var(--light-blue) 3px;
    height: 27px;
    color: var(--light-blue);
    margin-bottom: 10px;
    font-weight: bold;
}

select {
    border-radius: 10px;
    
    border: solid var(--light-blue);
    color: var(--light-blue);
    font-weight: bold;
    text-align: center;
    padding-bottom: 1px;
}
.orgadmin-button-container {
    display: flex;
    justify-content: center;

}


.orgadmin-button-save {
    width: 11%;
    height: 40px;
    background-color: var(--light-blue);
    color: white;
    border: 3px solid var(--dark-blue);
    border-radius: 10px;
    font-weight: bolder;
    margin-top: 0px;
    z-index: 5;
}
.orgadmin-button-save:hover {
    background-color: var(--dark-blue);
    border: 3px solid var(--light-blue);
    cursor: pointer;
    transform: scale(1.05);

}

.orgadmin-button-cancel {
    width: 8%;
    height: 40px;
    background-color: var(--white);
    color: var(--light-blue);
    border: none;
    font-weight: bolder;
    margin-top: 0px;
}
.orgadmin-button-cancel:hover {
    color: var(--dark-blue);
    cursor: pointer;
    transform: scale(1.06);
    z-index: 0;
}

.orgadmin-document {
    display: flex;
    /*
    display: flex;
    justify-content: space-between;
    background-color: white;
    padding: 5px;
    border-radius: 10px;
    margin: 10px;
    margin-bottom: 0px;
    border: 3px solid var(--dark-blue);
    */

}
.orgadmin-document-source-container {
    height: 35px;
}

.orgadmin-document-source-container, .orgadmin-document-content-container {
    margin: 15px;
    margin-top: 0px;
    margin-bottom: 5px;
    border-radius: 10px;
    padding: 2px;
    width: 400px;
    background-color: white;
    overflow: hidden;
    text-align: center;
    border: 2px solid var(--dark-blue);
}

.user-records-entry-row-overflow {
    overflow: hidden;
    min-width: 100px;
    padding-bottom: 15px;
}
.orgadmin-document-source-container p, .orgadmin-document-content-container p{
    color: var(--light-blue);
    font-size: 0.9rem;
    margin: 3px;
}

.orgadmin-document-content-container {
    width: 750px;
    margin-left: 0px;
    margin-right: 0px;
    text-align: left;
}
.orgadmin-upload-button:hover {
    background-color: var(--dark-blue);
    cursor: pointer;
    transform: scale(1.05);
    border: 3px solid var(--light-blue);

}
.orgadmin-document-modify {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.orgadmin-document-modify svg {
    width: 24px;
    height: 24px;
    margin-left: 5px;
    fill: white;
    margin-right: 5px;
}


/*DELETE THESE TO RESTORE*/
.orgadmin-document-content-container p, .user-records-entry-row-overflow  {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1; /* Show only one line by default */
    -webkit-box-orient: vertical;
    transition: max-height 0.3s ease; /* Smooth transition */
    max-height: 2em; /* Height for one line */
}


b{
    font-weight: bold;
    font-size: 1.05rem;
}
.current-site select {
    border-radius: 10px;
    color: var(--light-blue);
    border: 2px solid var(--dark-blue);
    margin-left: 10px;
}


.orgadmin-document-content-container p:hover, .user-records-entry-row-overflow:hover {
    white-space: normal; /* Allow text to wrap */
    overflow: hidden;
    -webkit-line-clamp: 5; /* Show up to five lines on hover */
    max-height: 10em; /* Adjust based on line height (1.2em * 5) */
}

.user-records-entry-row-overflow:hover {
    -webkit-line-clamp: 20; /* Show all lines on hover */
    max-height: 500em; /* Remove height limit */
    transition:cubic-bezier(1, 0, 0, 1) 1.4s;
}

.orgadmin-personal-info {
    display: flex;
    align-content: flex-start;
}

.orgadmin-personal-info svg {
    width: 20px;
    height: 20px;
    margin-left: 12px;
    fill: var(--dark-blue);
    margin-right: 5px;
    margin-top: 30px;

}

.orgadmin-organization-info-1, .orgadmin-organization-info-2 {
    display: flex;
    margin-left: 20px;
}

.orgadmin-organization-info-1 div input, .orgadmin-organization-info-2 div input {
    width: 200px;
    margin: 0px;
    margin-top: 18px;
    margin-left: 10px;
}

.welcome-text {
    color: var(--light-blue);
    text-align: center;

}


.welcome-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    padding: 20px;
    background-color: var(--white);
    border-radius: 8px;
  }

.orgadmin-organization-info-1 div h5, .orgadmin-organization-info-2 div h5 {
    text-align: start;
    padding: 0px;
    margin: 5px;
    margin-left: 10px;
    width: 200px;
    color: var(--light-blue);
}

.orgadmin-organization-info-1 div, .orgadmin-organization-info-2 div{
    display: flex;
    width: 100%;
    margin-right: 40px;

    place-content: space-between;
}

.orgadmin-documents-container {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    background-color: var(--light-blue);
    height: auto;
    min-height: 275px;
    overflow-y: scroll;
    border: 3px solid var(--dark-blue);
}

.orgadmin-upload-button {
    width: 50%;
    align-self: center;
    padding: 5px;
    background-color: var(--light-blue);
    color: white;
    border: 3px solid var(--dark-blue);
    border-radius: 10px;
    font-weight: bolder;
    font-size: 16px;
    margin-top: 20px;
}
.orgadmin-properties-text-container {
    display: flex;
    flex-direction: column;
}
.orgadmin-properties-container {
    display: flex;
    justify-content: space-around;
    margin-top: 20px;
}

.orgadmin-guidelines-container {
    display: flex;
}

/*=============================================================================================================*/

.logo {
    width: 60%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.login-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
}

.login-container {
    display: flex;
    width: 100vw;
    height: 100vh;
}

.text-container {
    width: 40%;
    background-color: var(--light-blue);
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.text-container label {
    color: white;
    font-weight: bold;
    font-size: 14px;
}

.text-container input {
    width: 100%;
    border-radius: 10px;
    border: solid white 1px;
    height: 30px;
    margin: 10px;
    color: var(--light-blue);
    margin-bottom: 20px;
    font-weight: bold;
}

.login-button {
    width: 100%;
    height: 40px;
    background-color: white;
    color: var(--light-blue);
    border: none;
    border-radius: 10px;
    font-weight: bolder;
    font-size: 16px;
    margin-top: 20px;
}

.text-container p {
    color: white;
    font-size: 14px;
    margin-top: 20px;
    font-weight: bold;
}

.login-container {
    background-image: url('../logos/WhiteBackground.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.login-button:hover {
    background-color: var(--dark-blue);
    color: white;
    cursor: pointer;
    transform: scale(1.05);
}


.tooltip {
    position: relative;
    cursor: pointer;
    color: var(--pure-white);
    background-color: var(--dark-blue); /* Background color of the circle */
    border-radius: 50%; /* Makes the background a circle */
    width: 15px; /* Adjust width as needed */
    height: 15px; /* Adjust height as needed */
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 11px; /* Adjust font size as needed */
    margin: 0px;
    margin-left: -20px;
    margin-bottom: -17px;
    z-index: 0;
}

.tooltip-container {
    display: flex;
    place-content: flex-start;
}

.tooltip .tooltip-text {
    visibility: hidden;
    width: 300px; /* Adjust width as needed */
    background-color: var(--light-blue); /* Background color of the tooltip */
    color: var(--pure-white); /* Text color of the tooltip */
    text-align: center;
    border-radius: 6px;
    border: 3px solid var(--dark-blue); /* Border color of the tooltip */
    padding: 5px;
    position: absolute;
    z-index: 999999999999999999;
    bottom: 125%; /* Position the tooltip above the icon */
    left: 50%;
    margin-left: -150px; /* Center the tooltip */
    opacity: 0;
    transition: opacity 0.3s;
}

.tooltip:hover .tooltip-text {
    visibility: visible;
    opacity: 1;
}

.orgadmin-form-row label {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
}

.orgadmin-upload-container  label {
    color: white;
    align-self: center;
    font-weight: bold;
    font-size: 1.2rem;
}
.orgadmin-method-of-transfer-container {
    display: flex;
    margin-top: 20px;
    justify-content: center;
    align-items: center;
}
.orgadmin-method-of-transfer-container button {
    width: 23%;
    padding: 5px;
    background-color: white;
    color: var(--light-blue);
    border: 3px solid var(--dark-blue);
    border-radius: 10px;
    font-weight: bold;
    margin-top: 0px;
    margin-left: 10px;
    margin-bottom: 10px;
}

.orgadmin-method-of-transfer-container button:hover {
    background-color: var(--dark-blue);
    color: white;
    border: 3px solid white;
    cursor: pointer;
}

.orgadmin-metadata-container {
    display: flex;
    flex-direction: column;
    background-color: white;
    padding: 20px;
    margin: 15px;
    margin-top: 10px;
    padding-top: 5px;
    border-radius: 20px;
    /*
    display: flex;
    align-content: center;
  justify-content: space-between; 
    margin-top: 20px;
    background-color: white;
    margin: 15px;
    border-radius: 20px;
    padding: 10px;
*/
    }

    .orgadmin-metadata-heading {
        display: flex;
        justify-content: space-around;
    }
    .orgadmin-metadata-heading h4 {
        color: var(--light-blue);
        font-size: 1.2rem;
        font-weight: bold;
        margin: 5px;
    }
.orgadmin-metadata-input {
    display: flex;
    justify-content: space-between;
    
}

.orgadmin-metadata-add-or-sub {
    margin-top: 0px;
    padding: 0px;
}

.orgadmin-metadata-add-or-sub p {
    color: var(--light-blue);
    font-weight: bolder;
    font-size: 1.7rem;
    margin: 0;
    padding: 0;
    text-align: top;
    align-self: flex-start;
}

.orgadmin-metadata-input-name {
    align-self: right;
    width: 45%;
}

.orgadmin-metadata-input-value {
    align-self: left;
    width: 45%;

}

.orgadmin-source-text {
    width: calc(100% - 60px);
    padding: 10px;
    margin: 15px;
    color: var(--light-blue);
}

.orgadmin-metadata-input-container2 div {
    display: flex;
    padding-top: 0px;
    margin-top: 0px;
    align-content: start;
}
.orgadmin-metadata-input-container2 div p {
    color: var(--light-blue);
    font-weight: bolder;
    font-size: 1.7rem;
    margin: 0;
    margin-left: 10px; 
}

.orgadmin-metadata-mini-container h4 {
    color: var(--light-blue);
    font-weight: bold;
    align-self: center;
    margin-top: 0px;
    padding-top: 0px;
}

.orgadmin-metadata-mini-container input {
    width: 75%;
    align-self: center;
}

.orgadmin-metadata-input-container {
    align-self: center;
}

.orgadmin-metadata-input-container input {
    width: 75%;
}


.settings-icon:hover path{
    fill: var(--grey);
    color: var(--grey);
    cursor: pointer;
}

.orgadmin-grid-headings {
    display: flex;
    justify-content: space-around;
    align-content: center;
}

.orgadmin-grid-headings h3 {
    color: white;
    font-size: 1.2rem;
    font-weight: bold;
    margin: 5px;
}

/*==============================================BOT PLAN CSS===================================================*/
.carousel-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  box-sizing: border-box;
}

.carousel-content {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  width: 100%;
}

.bot-plan-card {
  background-color: var(--light-blue);
  color: var(--pure-white);
  flex: 1 0 45%;
  margin: 0 30px;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.8);
  transition: transform 0.3s ease;
  max-width: 23%;
  max-height: 350px;
  min-width: 23%;
  min-height: 350px;
  margin-bottom: 20px;
  margin-top: 20px;
}

.home-botplan-rules {
  line-height: 2.1;
}

.bot-plan-card:hover {
  transform: scale(1.05);
}

.carousel-container button {
  background-color: var(--dark-blue);
  color: var(--pure-white);
  border: none;
  margin-left: 50px;
  margin-right: 50px;
  padding: 10px 20px;
  font-size: 1.5rem;
  cursor: pointer;
  border-radius: 50%;
  transition: background-color 0.3s ease;
}

.carousel-container button:disabled {
  background-color: grey;
  cursor: not-allowed;
}

.carousel-container button:not(:disabled):hover {
  background-color: var(--pure-white);
  color: var(--dark-blue);
}

.bot-plan-card:hover {
  cursor: default;
}


/*=========================================SETTINGS.TSX STARTS HERE==============================================*/
.settings {
    display: flex;
    align-content: center;
    justify-content: space-between;
}

.setting {
    width: 47%;
}




/* Dropdown.css */
.dropdown {
    position: relative;
    display: inline-block;
  }
  
  .dropdown-button {
    color: white; /* White text */
    margin-top: 13px;
    font-size: 16px; /* Set a font size */
    border: none; /* Remove borders */
    cursor: pointer; /* Add a pointer on hover */
  }
  
  .dropdown-content {
    display: none;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    background-color: var(--light-grey); /* Grey background */
    min-width: 160px; /* Set a min-width */
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2); /* Add a shadow */
    z-index: 1; /* Make sure it does not overlap */
  }
  
  .dropdown-content a {
    color: black; /* Black text */
    padding: 12px 16px; /* Some padding */
    text-decoration: none; /* Remove underline */
    display: block; /* Make it block so it takes full width */
  }
  
  .dropdown-content a:hover {
    background-color: var(--grey); /* Grey background on hover */
  }
  
  .dropdown:hover .dropdown-content {
    display: block; /* Show the dropdown menu on hover */
  }
  
  .dropdown:hover .dropdown-button {
    fill: var(--grey); /* Darker green on hover */
  }


/*=========================================SETTINGS.TSX ENDS HERE==============================================*/

 /*=========================================SignUp.tsx======================================================*/
.signup-container {
    display: flex;
    width: 100vw;
    min-height: 100vh; /* Ensures the background color covers at least the viewport height */
    background-color: var(--light-blue);
    overflow: auto; /* Allows the container to scroll if content overflows */
  }
  
  .signup-water-drop-img {
    width: 130px;
    height: 225px;
  }
  
  .signup-container-left, .signup-container-right {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    padding: 30px;
    background-color: var(--light-blue);
  }
  
  .signup-container-left {
    width: 55vw;
    margin: 0;
  }
  
  .signup-container-right {
    width: 45vw;
    margin: 0;
    padding: 5px;
    padding-right: 13px;
    
  }



  /* PROGRESS BAR */
  .signup-progress-panel {
    width: 180px;
    background-color: var(--light-blue);
    align-self: center;
    border-radius: 5px;
    font-family: 'Arial', sans-serif;
}

.signup-progress-steps {
    list-style-type: none;
    padding: 0;
    margin: 0;
    position: relative;
    width: 80%;
    justify-self: flex-end;
}

.signup-progress-steps li {
    margin: 20px 0;
    position: relative;
    padding-left: 30px;
    color: var(--dark-blue); /* White text color */
    font-size: 0.9rem;
    padding: 20px;
}

.signup-progress-steps li::before {
    content: "";
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: var(--dark-blue); /* White circle */
    position: absolute;
    left: 0;
    top: 0;
}

.signup-progress-steps li.completed::before {
    content: "";
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: var(--dark-blue); /* Blue circle */
    position: absolute;
    left: 0;
    top: 0;
}

.signup-progress-steps li:last-child::before {
    font-size: 25px;
    color: var(--dark-blue); /* Black color for the checkmark */
    text-align: center;
    line-height: 5px;
    background-color: var(--dark-blue); /* Keep the background white */
}

.signup-progress-steps li::after {
    content: "";
    position: absolute;
    width: 2px;
    height: calc(100% - 10px); /* Extend the line slightly beyond the element */
    background-color: var(--dark-blue); /* White line */
    top: 15px;
    left: 7px;
}



.signup-progress-steps li.completed::before {
    content: "\2713"; /* Checkmark for completed step */
    font-size: 19px;
    color: var(--pure-white);
    display: block;
    text-align: center;
    line-height: 3px;
}

.signup-progress-steps li.active::before {
    background-color: var(--dark-blue); /* Dark blue circle for active step REMOVED*/
}

.signup-progress-steps li.completed::after, .signup-progress-steps li.active::after {
    background-color: var(--dark-blue); /* Keep the line white for completed steps */
}

.signup-progress-steps li.active::after {
    background-color: var(--pure-white); /* Keep the line white for completed steps */
}

.signup-progress-steps li.active {
    font-weight: bold;
}




  
  .signup-container h1 {
    color: var(--light-blue);
    font-size: 1.9rem;
    font-weight: bold;

  }
  
  .signup-container-left h1 {
    color: white;
    text-align: center;
  }
  
  .signup-header {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-content: space-between;
  }
  
  .signup-logo-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-content: flex-start;
  }
  
  .signup-icon-img {
    width: 80px;
    height: 80px;
  }
  
  .signup-form h5 {
    color: white;
    font-size: 1.1rem;
    margin: 0px;
    margin-top: 7px;
    text-decoration: underline;
  }
  .signup-name-img {
    height: 25px;
    width: 120px;
  }
  
  .signup-signin-text {
    color: white;
    font-size: 1.1rem;
    margin: 0px;
    margin-top: 20px;
  }
  
  .signup-form {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
  }
  
  .signup-form div {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  
  .signup-form div div {
    display: flex;
    flex-direction: column;
    width: 48%;
  }
  
  .signup-form div div label {
    color: white;
    font-weight: bold;
    font-size: 0.8rem;
    padding-left: 4px;
    margin: 0px;
  }
  
  .signup-form div div input {
    width: 93%;
    height: 23px;
    font-size: 0.8rem;
    margin: 3px;
    border-radius: 5px;
  }
  
  .signup-form h1 {
    color: white;
    margin: 0px;
    font-size: 1.5rem;
    align-self: flex-start;
    margin-top: 10px;
    margin-bottom: 0px;
    padding-bottom: 0px;
  }

  .signup-buttons-container {
    justify-content: center !important;
  }
  
  .signup-buttons-signup {
    width: 40%;
    margin-top: 10px;
    padding: 3px;
    color: var(--light-blue);
    background-color: white;
    border: 3px solid var(--dark-blue);  
    border-radius: 10px;
    font-weight: bold;
    font-size: 1rem;
  }

  .signup-buttons-signup:hover {
    background-color: var(--dark-blue);
    color: white;
    cursor: pointer;
    transform: scale(1.05);
  }

  .signup-buttons-cancel {
    border: none;
    background-color: var(--light-blue);
    color: white;
    padding: 3px;
    margin-top: 10px;
    margin-left: 10px;
    font-weight: bold;
    font-size: 1rem;
  }

  .signup-buttons-cancel:hover {
    color: var(--dark-blue);
    cursor: pointer;
    transform: scale(1.05);
  }

  .orgadmin-plan-signup {
    border-radius: 0px;
    padding: 10px;
    background-color: white;
    width: 290px !important;
  }

  .orgadmin-plan-signup-dark {
    background-color: var(--dark-blue);
  }

  .orgadmin-plan-signup ul li, .orgadmin-plan-signup p {
    line-height: 20px;
    font-size: 0.7rem;
  }

  .orgadmin-plan-signup ul li b {
    font-weight: bold;
    font-size: 0.8rem;
  }

  .orgadmin-plan-signup-light h3, .orgadmin-plan-signup-light p, .orgadmin-plan-signup-light ul li {
    color: var(--light-blue);
  }

  .change-this-color {
    color: var(--light-blue);
  }

  .orgadmin-screen-div {
    min-height: 100vh;
    min-width: 100%;
    position: relative;
    border: 2px solid var(--dark-blue);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.6);
    background-color: var(--light-blue);
  }

  .orgadmin-properties-inputs-container input, .orgadmin-properties-inputs-container select, .orgadmin-properties-inputs-container label {
    margin-bottom: 15px;
    font-weight: 600;
  }
  

  /*IDLE HEREEEEEE ------------------------------------------------------------------------------*/
  
  .modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* semi-transparent background */
    backdrop-filter: blur(5px); /* apply blur filter */
    display: flex;
    align-items: center;
    z-index: 9999999;
    justify-content: center;
  }
  
  .modal-content {
    background-color: var(--pure-white);
    padding: 20px;
    border-radius: 4px;
    width: 50%;
  }

  .modal-loading-content {
    width: 70px;
    height: 55px;
    display: flex;
    align-content: center;
    justify-content: center;

  }
  
  .modal h2 {
    margin-top: 0;
  }
  
  .modal p {
    margin-bottom: 20px;
  }
  
  .modal button {
    width: 50%;
    padding: 10px 20px;
    /* margin-right: 10px; */
    border: none;
    border-radius: 4px;
  
    color: var(--pure-white);
    font-size: 16px;
    cursor: pointer;
  }
  
  .modal button:hover {
    background-color: var(--light-blue);
  }
  
  .modal button:last-child {
    margin-right: 0;
  }





  /*WELCOME PAGE CSS*/
  .welcome-container {
    padding: 20px;
    font-family: Arial, sans-serif;
    line-height: 2;
    color: var(--light-blue);
  }
  
  .welcome-text {
    font-size: 2em;
    color: var(--light-blue);
  }
  
  .click-here:hover {
    cursor: pointer;
    transform: scale(1.05);
  }
  
  .welcome-container .click-here-link {
    color: var(--dark-blue);
    cursor: pointer;
    text-decoration: underline;
  }
  
  .welcome-container .settings-link {
    color: var(--dark-blue);
    text-decoration: underline;
    cursor: pointer;
  }
  
  .welcome-container pre {
    background-color: white;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    overflow-x: auto;
    color: var(--dark-blue);
    margin-left: 20px; /* Same margin as li text */
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  }
  
  .welcome-container code {
    font-family: 'Courier New', Courier, monospace;
    font-size: 0.95em;
    color: var(--light-color);
    
  }
  
  .welcome-container ul,
  ol {
    margin-left: 20px;
  }
  
  .welcome-container b {
    font-weight: 700; /* Increase font weight for bold text */
    color: var(--dark-blue);
  }
  
  
  
  







  .carousel-container {
    min-width: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.carousel-button {
    background-color: var(--light-blue);
    border: none;
    color: white;
    padding: 10px;
    border-radius: 500px;
    padding: 10px;
    line-height: 13px;
    cursor: pointer;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

.carousel-button.prev {
    left: 0;
}

.carousel-button.next {
    right: 0;
}

.orgadmin-plans-container {
    display: flex;
    overflow: hidden;
    width: 363px;
}

.orgadmin-plan {
    transition: transform 0.5s ease-in-out;
}

.loading-analytics {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    width: 50px;
}

.loading-analytics-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 80vh;
}

.loading-analytics-text {
    color: var(--light-blue);
    font-size: 2rem;
}

.analytics-plan-limitations-ul {
    list-style: none;
    color: var(--light-blue);
}



/*=========================================UserRecords.tsx======================================================*/
.user-records-container {
    width: 100%;
    margin: 0px;
    box-sizing: border-box;
}

.user-records-heading {
    background-color: var(--dark-blue);
    border: 3px solid var(--light-blue);
    text-shadow: -1.3px 0 var(--light-blue), 0 1.3px var(--light-blue), 1.3px 0 var(--light-blue), 0 -1.3px var(--light-blue);
    color: white;
    width: 100%;
    text-align: center;
    padding: 10px;
    margin-top: 0px;
}

.sort-by-section {
    margin-bottom: 10px;
}

.sort-by-section div {
    display: inline-block;
    margin-right: 15px;
    cursor: pointer;
}

.user-records-table {
    width: 100%;
    border-collapse: collapse;
}


.user-records-table th,
.user-records-table td {
    padding: 4px;
    text-align: left;
    border: none;
}


.user-records-table th {
    background-color: var(--light-blue);
    color: var(--pure-white);
    font-weight: bold;
    text-align: center;
    cursor: pointer;
}

.user-record-row {
    font-size: 0.9em; /* Smaller font size for the table entries */
}

.action-button {
    display: inline-block;
    cursor: pointer;
    background: none;
    fill: var(--pure-white);
    border: none;
    line-height: 0px;
    padding: 0px;
    height: 23px;
}

.pagination {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
}

.page-numbers {
    display: flex;
}

.page-number {
    margin: 0 5px;
    cursor: pointer;
    color: var(--light-blue);
}

.page-number.active {
    font-weight: bold;
    text-decoration: underline;
    font-size: 1.15rem;
}

.page-of {
    color: var(--light-blue);
    font-weight: bold;
}

.user-records-table-container {
    margin: 0px;
    margin-top: 20px;
    
    overflow-x: auto;
    width: calc(100% - 20px);
    background-color: var(--light-blue);
    border: 2px solid var(--dark-blue);
    padding: 10px;
}

.user-records-entry-row {
    background-color: var(--pure-white);
    color: var(--light-blue);
    font-size: 0.7rem;
    padding: 7px;
    border-radius: 10px;
    overflow: hidden;
    text-align: center;
    border: 2px solid var(--dark-blue);
}

/*=======================================CHAT HISTORY======================================================*/

.chat-history-modal-content {
    background-color: var(--light-blue);
    padding: 20px;
    border-radius: 4px;
    border: 4px solid var(--dark-blue);
}

.chat-history-header {
    display: flex;
    justify-content: space-between;
    align-items: start;
}

.chat-history-header-title {
    color: var(--pure-white);
    font-size: 1.5rem;
    margin: 0;
    line-height: 1;
    padding-left: 50px;
    margin-bottom: 15px;
    font-weight: bold;
    justify-self: center;
    align-self: center;
}

.chat-history-header-title-text {
    color: var(--pure-white);
    justify-self: center;
    align-self: center;
    margin: 0;
    line-height: 1;
    margin-left: 30px;
}

.chat-history-exit {
    cursor: pointer;
    font-size:1.5rem;
    align-self: flex-start;
    margin: 0px;
}

.chat-history-date {
    color: var(--pure-white);
    font-size: 1.1rem;
    align-self: flex-start;
    margin: 0px;
}

.chat-history-convo-container {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    background-color: var(--pure-white);
    padding: 20px;
    border-radius: 4px;
    max-height: 225px;
    overflow-y: scroll;
    border: 2px solid var(--dark-blue);
}

.chat-history-convo-individual-container {
    display: flex;
    justify-content: space-between;
}

.chat-history-convo-individual-container-bot {
    color: var(--light-blue);
    font-weight: bold;
    font-size: 1.1rem;
}

.chat-history-convo-individual-container-chat {
    color: var(--light-blue);
    text-align: left;
    flex-grow: 1;
    margin: 0px;
    margin-right: 10px;
    margin-top: 2px;
    margin-left: 10px;
}

.chat-history-convo-individual-container-time {
    color: var(--light-blue);
    text-align: right;
    margin: 0px;
    min-width: 70px;
    margin-left: 10px;
    margin-top: 2px;
}

.loadingGIF {
    justify-content: center;
    align-items: center;
    height: 50px;
    width: 50px;
}

.orgadmin-subheading-subheading {
    color: var(--light-blue);
    font-size: 1.2rem;
    font-weight: bold;
    margin-top: 20px;
    align-self: center;
    margin-right: 90px;
}

.piecharts-container {
    display: flex;
    justify-content: space-between;
    align-content: space-between;
}

.piecharts-container-mini {
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;
}

tspan {
    fill: var(--light-blue);
}

.analytics-filter-container {
    display: flex;
    justify-content: space-around;
    align-content: center;
    margin-top: 15px;
    margin-bottom: 20px;
}

.analytics-filter-container p {
    color: var(--light-blue);
    font-weight: bold;
    font-size: 1.1rem;
    margin: 0px;
    margin-right: 10px;
}

.analytics-filter-container div select {
    width: 170px;
    height: 30px;
    border-radius: 9px;
    border: 2px solid var(--dark-blue);
    color: var(--light-blue);
    text-align: center;
}

.analytics-filter-container div select:disabled {
    background-color: var(--light-blue);
    color: var(--pure-white);
}

.BarChart {
    display: flex;
    justify-content: center;
    align-content: center;
    margin-top: 20px;
}

.analytics-switch-button {
    background-color: var(--light-blue);
    border: 3px solid var(--dark-blue);
    color: var(--pure-white);
    padding: 5px;
    border-radius: 10px;
    font-weight: bold;
    cursor: pointer;
    margin-top: 20px;
}

.analytics-switch-button:hover {
    background-color: var(--pure-white);
    border: 3px solid var(--light-blue);
    color: var(--light-blue);
}

.logout-svg {
    width: 120px;
    height: 120px;
    fill: var(--light-blue);
}

.logout-modal {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.logout-modal h2, .logout-modal p, .logout-modal {
    color: var(--dark-blue);
    font-weight: bold;
}

.logout-modal button {
    background-color: var(--light-blue);
    border: 3px solid var(--dark-blue);
    color: var(--pure-white);
    padding: 10px;
    border-radius: 10px;
    font-weight: bold;
    cursor: pointer;
    min-width: 100px;
    width: auto;
    margin: 20px;
}



.subscription-summary-container {
    display: flex;
    justify-content: space-between;
    align-content: center;
    background-color: var(--pure-white);
    padding: 10px;
    border-radius: 5px;
    flex-direction: column;
    margin-bottom: 25px;
    margin-top: 40px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    font-family:  -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
    
}

.subscription-summary-plan-container {
    display: flex;
    justify-content: space-between;
    align-content: center;
    border-radius: 5px;
    color: rgb(160, 160, 160);
}

.subscription-summary-plan-container {
    position: relative;
    padding-bottom: 10px;
}

.subscription-summary-plan-container::after {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 1px;
    background-color: #e0e0e0; /* Light grey color for the line */
}

.subscription-summary-total-container {
    display: flex;
    justify-content: space-between;
    align-content: center;
    border-radius: 5px;
    
}

.apply-font-sub {
    font-family:  -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
    font-size: 1.3rem;
    color: rgb(91, 91, 91);
}

.apply-font-2 {
    color: rgb(160, 160, 160);
}

span#button-text, button#submit {
    background-color: var(--light-blue);
    border: none;
    font-family:  -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
    font-size: 1.3rem;
    color: var(--pure-white);
}

div#payment-message {
    color: red;
    font-family:  -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
    font-size: 1.3rem;
}

button#submit {
    padding: 15px;
    border-radius: 5px;
    font-weight: bold;
    cursor: pointer;
    margin-top: 20px;
    width: 100%;
}

.payment-success-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  
  .payment-success-card {
    background-color: var(--pure-white);
    border: 1px solid var(--grey);
    border-radius: 10px;
    padding: 2rem;
    text-align: center;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .payment-success-card h1 {
    color: var(--dark-blue);
    margin-bottom: 1rem;
  }
  
  .payment-success-card p {
    color: var(--light-blue);
    font-size: 1.6rem;
  }

  .orgadmin-plans-selected-container-outside {
    padding: 0px;
    margin-left: 40px;
    margin-right: 40px;
    border-radius: 30px;
    background-color: var(--dark-blue);
    box-shadow: 2px 6px 8px rgba(0, 0, 0, 0.5);
    border: 3px solid var(--pure-white);
    transition: transform 0.5s ease-in-out;
  }

  .orgadmin-plans-container-outside {
    transition: transform 0.5s ease-in-out;
    margin-left: 40px;
    margin-right: 40px;

  }

  .home-step-carousel-container {
    display: none;
  }

  .orgadmin-plans-selected-container-outside-h1 {
    color: var(--pure-white);
    font-size: 1rem;
    font-weight: bold;
    margin: 0px;
    padding: 10px;
    text-align: center;
    margin-bottom: -20px;
    
  }
.properties-template-container {
    display: inline-flex;
    border: 4px solid var(--light-blue);
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.4);
}

.properties-template-container:hover {
    cursor: pointer;
    transform: scale(1.05);
}


  .properties-template-color{
    min-width: 40px;
    min-height: 95px;

  }

  .properties-subheading {
    color: var(--light-blue);
    font-size: 1.1rem;
    font-weight: bold;
    text-decoration: none;
  }

  .properties-template-names {
    color: var(--light-blue);
    text-align: center;
    font-weight: 600;
  }

  .orgadmin-button-save:disabled {
    background-color: grey;
    color: var(--pure-white);
    cursor: not-allowed;
    border: 2px solid black;
  }

  /*==============================FORGOTPASSWORD.TSX===================================*/
  .forgot-password-background {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .forgot-password-container {
    display: flex;
    background-color: var(--pure-white);
    padding: 30px;
    max-width: 450px;
    max-height: 400px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 10px solid var(--light-blue);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.6);
  }

  .forgot-password-container label, .forgot-password-container input, .forgot-password-container h3 {
    margin: 10px;
    color: var(--light-blue);
    text-align: center;
    font-weight: bold;
  }
  .forgot-password-container label, .forgot-password-container input{
    margin: 0px;
    text-align: left;
    align-self: flex-start;
    font-weight: normal;
  }

  .forgot-password-container button {
    background-color: var(--light-blue);
    color: var(--pure-white);
    border: none;
    border-radius: 10px;
    padding: 6px;
    width: 50%;
    font-weight: bold;
    cursor: pointer;
    margin-top: 20px;
  }

  .forgot-password-container button:hover {
    background-color: var(--dark-blue);
    transform: scale(1.03);
  }
  .error {
    color: red;
    margin-bottom: 0px;
    padding-bottom: 0px;
    margin-top: 12px;
  }
  


  /*==============================SORTABLELIST.TSX===================================*/
  .list-container {
    background-color: var(--light-blue);
    padding: 20px;
    width: 300px;
    margin: auto;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
  }
  
  .list-item {
    background-color: var(--pure-white);
    color: var(--light-blue);
    border-radius: 5px;
    padding: 10px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    opacity: 1; /* Normal opacity */
  }
  
  .list-item.disabled {
    background-color: #dddddd; /* Light grey background */
    color: #a0a0a0; /* Grey text color */
    border: 1px dashed #a0a0a0; /* Dashed border */
    cursor: not-allowed; /* Not-allowed cursor */
  }

  .list-item.disabled span {
    opacity: 0.8;
  }
  
  .list-item button {
    margin-left: 10px;
    background-color: var(--light-blue);
    color: var(--pure-white);
    border: none;
    padding: 5px 10px;
    cursor: pointer;
    border-radius: 3px;
    transition: background-color 0.3s ease;
  }

  .list-item.disabled button:hover {
    background-color: var(--dark-blue); /* Add a hover effect for buttons */
    transform: scale(1.03);
  }
  
  
  
  /* Ensure the button inside disabled list items does not look disabled */
  .list-item.disabled button {
    background-color: var(--light-blue); /* Normal background color */
    color: var(--pure-white); /* Normal text color */
    cursor: pointer; /* Normal cursor */
    opacity: 1; /* Normal opacity */
    border: none; /* Remove any border */
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1); /* Add box shadow for better visibility */
  }

  .tooltip-priority-list {
    margin: 0;
    margin-left: 10px;
    bottom: -10px;
  }
  

  /*=======================================CANCEL SUBSCRIPTION.TSX==========================================*/
  .cancel-subscription-next-button {
    background-color: var(--light-blue);
    border: none;
    color: var(--pure-white);
    padding: 0px;
    border-radius: 5px;
    font-weight: bold;
    cursor: pointer;
    margin-top: 20px;
    width: 30%;
  }

  .cancel-subscription-next-button:hover {
    background-color: var(--dark-blue);
    transform: scale(1.03);
  }

  .cancel-subscription-back-button {
    background-color: none;
    border: none;
    color: var(--light-blue);
    padding: 10px;
    font-weight: bold;
    cursor: pointer;
    margin-top: 20px;
  }

  .cancel-subscription-back-button:hover {
    color: var(--dark-blue);
    transform: scale(1.03);
  }

  .cancel-subscription-button {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--dark-blue);
    border: none;
    color: var(--pure-white);
    padding: 10px;
    border-radius: 5px;
    font-weight: bold;
    cursor: pointer;
    margin-top: 20px;
    width: 30%;
  }
  
  /* Home.css */

.home-container {
  font-family: Arial, sans-serif;
}

.home-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--light-blue);
  padding: 1rem 2rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.home-logo img {
  height: 40px;
  margin-right: 0.5rem;
}

.home-logo h1 {
  display: inline;
  font-size: 1.5rem;
  color: #333;
}

.home-nav {
  display: flex;
}

.home-nav a {
  margin: 0 1rem;
  text-decoration: none;
  color: #333;
}

.home-auth-buttons a {
  margin-left: 1rem;
  text-decoration: none;
  padding: 0.5rem 1rem;
  border-radius: 5px;
}

.home-signin-button {
  background-color: var(--pure-white);
  color: var(--light-blue);
  font-size: 1.2rem;
}

.home-signup-button {
  background-color: none;
  color: white;
  margin: 0;
  padding: 0 !important;
  margin-right: 20px;
  font-size: 1.2rem;
}

.home-hero {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 3rem 1rem;
  background: #e9ecef;
  height: 80vh;
  
}

.home-hero-content {
  max-width: 60%;
  display: flex;
  flex-direction: column;
}

.home-hero-content button {
  width: 50%;
  border-radius: 5px;
  border: none;
  padding: 0.5rem 1rem;
  background-color: var(--light-blue);
  color: white;
  align-self: center;
  margin-top: 30px;
  margin-left: -150px;
  font-weight: bold;
}

.home-hero-content button:hover {
  background-color: var(--dark-blue);
  cursor: pointer;
}

.home-hero-content h2 {
  font-size: 2.5rem;
  margin-bottom: 1rem;
  margin-left: -50px;
}

.home-hero-content p {
  font-size: 1.1rem;
  margin-left: -50px;
}

.home-hero-image img {
  max-width: 80%;
  height: auto;
  
}

.home-hero {
  position: relative;
  background-image: url('../logos/fog2.gif');
  
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.home-hero::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(85, 112, 138, 0.6); /* Using --dark-blue with 70% opacity */
  background: linear-gradient(180deg,rgba(0, 22, 42, 0.86),rgba(85, 173, 255, 0.3));
}

.home-hero-content {
  position: relative;
  z-index: 1;
  color: white; /* Adjust text color for visibility */
}

.home-hero-image img {
  max-width: 100%;
  height: auto;
  position: absolute;
  bottom: 25px;
  right: 8%;
  height: 80%;
}

.home-steps, .home-botplans {
  text-align: center;
  padding: 2rem 60px;
  background-color: var(--pure-white);
}

.home-steps {
  margin-bottom: 0;
  padding-bottom: 0;
}

.home-steps h2, .home-botplans h2 {
  font-size: 2rem;
  margin-bottom: 1rem;
  color: var(--dark-blue);
  text-decoration: underline;
}

.home-botplans {
  background-color: transparent;
}

.home-steps p, .home-botplans p {
  font-size: 1.15rem;
  margin-bottom: 2rem;
  color: var(--light-blue);
}


.home-steps-container {
  display: flex;
  justify-content: space-around;
}

.home-step {
  max-width: 20%;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 1rem;

}

.home-step img {
  max-width: 100px;
  height: auto;
  margin-bottom: 1rem;
}

.home-step h3 {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
  color: var(--dark-blue);
}

.home-step p {
  font-size: 1rem;
  line-height: 2.2;
}



/*CSS HERE FOR HOME STEPS NEW*/
.home-steps-container {
  display: flex;
  justify-content: space-around;
  position: relative;
  padding: 2rem 0;
  padding-bottom: 0;
  margin-bottom: 0;
}

.home-step {
  max-width: 20%;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 1rem;
  position: relative;
}

.home-step::before {
  content: "";
  position: absolute;
  top: 10%;
  right: -50%; /* Half of the spacing between the steps */
  width: 80%; /* Adjust according to spacing */
  height: 3px;
  background: repeating-linear-gradient(90deg, var(--light-blue), var(--light-blue) 5px, transparent 5px, transparent 10px);
}

.home-step:last-child::before {
  content: none;
}

.home-step img {
  max-width: 100px;
  height: auto;
  margin-bottom: 1rem;
}

.home-step h3 {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
  color: var(--dark-blue);
}

.home-step p {
  font-size: 1rem;
  line-height: 2.2;
}


.home-my-get-in-touch {
  background-image: url('../logos/WhiteBackground.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}


.home-botplans-mega-container  {
  background-image: url('../logos/WhiteBackground.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}


.home-botplans p {
  margin-bottom: 0;
}

.home-botplans {
  margin-bottom: 0px;
  padding-bottom: 0px;
  padding-top: 0px;
}


.home-icon-wrapper {
  background-color: var(--light-blue);
  padding: 10px;
  border-radius: 30px;
  width:40%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
  
}

/* Code given goes here */

.home-business {
  text-align: center;
  padding: 2rem 60px;
  background-color: var(--pure-white);
}

.home-business h2 {
  font-size: 2rem;
  margin-bottom: 1rem;
  color: var(--dark-blue);
}

.home-business p {
  font-size: 1.15rem;
  margin-bottom: 2rem;
  color: var(--light-blue);
}

.home-business-container {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.home-business-feature {
  max-width: 22%;
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 1rem;
  background-color: var(--light-blue);
  padding: 1rem;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
  border: 2px solid var(--dark-blue);
}

.home-business-feature h3 {
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
  color: var(--pure-white);
  font-weight: bold;
}

.home-business-feature p {
  font-size: 1rem;
  line-height: 1.5;
  color: var(--dark-blue);
}

.home-features {
  text-align: center;
  padding: 2rem 1rem;
  background-color: var(--pure-white);
}

.home-features h2 {
  font-size: 2rem;
  margin-bottom: 1rem;
  color: var(--dark-blue);
}

.home-features-container {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.home-feature {
  max-width: 30%;
  text-align: center;
  margin: 1rem;
}

.home-feature img {
  max-width: 100px;
  height: auto;
  margin-bottom: 1rem;
}

.home-feature h3 {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
  color: var(--dark-blue);
}

.home-feature p {
  font-size: 1rem;
  line-height: 1.5;
  color: var(--light-blue);
}


.home-choose-us-text-container {
  display: flex;
 place-content: center;
 flex-direction: column;
 max-width: 45%;
}

.home-choose-us-text-container h2, .home-choose-us-text-container h2 b {
  font-size: 2rem;
  margin-bottom: 0;
  color: var(--dark-blue);
  text-decoration: underline;

}

.home-choose-us-text-container h2 b {
  color: var(--light-blue);

}

.home-choose-us-reason-container {
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
  margin-right: 40px;
}

.home-choose-us-reason-text h3 {
  font-size: 1.3rem;
  color: var(--dark-blue);
  margin-bottom: 0;
line-height: 1;
}

.home-choose-us-reason-text p {
  font-size: 0.9rem;
  color: var(--light-blue);
  line-height: 2;
}

.home-choose-us-container {
  background-image: url('../logos/WhiteBackground.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  margin: 0;
  padding: 20px;
  padding-right: 70px;
  padding-left: 70px;
  left: 0;
}

.home-icon-wrapper-choose-us {
  width: auto;
  height: 50px;
  margin-right: 25px;
  margin-top: 10px;
}

.person-and-robot-img {
  width: 80%;
  height: auto;
  align-self: center;
  justify-self: center;
  margin-left: 20px;
}

.home-business-feature h1 {
  font-size: 4rem;
  align-self: center;
  text-align: center;
  margin: 0;
  padding: 0;
  line-height: 1;
  color: var(--pure-white);
  font-weight: bold;
}

.home-business button {
  background-color: var(--dark-blue);
  border: none;
  color: var(--pure-white);
  padding: 10px;
  border-radius: 5px;
  font-weight: bolder;
  cursor: pointer;
  margin-top: 20px;
  width: 30%;
}

.home-business button:hover {
  background-color: var(--light-blue);
  transform: scale(1.03);
}

.home-get-in-touch {
  
  display: flex;
  justify-content: space-between;
  align-items: space-between;
  padding: 20px;
  padding-left: 100px;
  padding-right: 100px;
}

.home-get-in-touch-text {
  display: flex;
  flex-direction: column;
  margin-left: 20px;
}

.home-get-in-touch-text h2 {
  font-size: 2rem;
  margin-bottom: 0;
  color: var(--dark-blue);
  text-decoration: underline;
}

.home-get-in-touch-text p {
  font-size: 1.2rem;
  color: var(--light-blue);
  line-height: 1.5;
}
  .home-get-in-touch button {
    background-color: var(--dark-blue);
    border: none;
    color: var(--pure-white);
    padding: 10px;
    border-radius: 5px;
    font-weight: bolder;
    cursor: pointer;
    margin-top: 20px;
    width: 30%;
    align-self: center;
  }

  .home-get-in-touch button:hover {
    background-color: var(--light-blue);
    transform: scale(1.03);
  }

  .home-footer-content {
    display: flex;
    padding: 10px;
    padding-left: 70px;
    padding-right: 70px;
    flex-direction: column;
    align-items: center;
    background-color: var(--light-blue);
  }

  .home-footer-logo {
    display: flex;
    align-items: center;
    flex-direction: column;
    
    margin: 20px;
  }

  .home-footer-logo-logo-img {
    width: 120px;
    height: auto;
  }

  .home-footer-logo-logo-text {
    height: 40px;
    margin-top: 15px;
    width: auto;
  }

  .home-footer-nav {
    display: flex;
    justify-content: space-around;
  }

  .home-footer-bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  .home-footer-link {
    font-size: 1.2rem;
    text-decoration: none;
    text-decoration: underline;
    color: var(--pure-white);
    margin-left: 50px;
    margin-right: 50px;
  }

  .home-footer-link:hover {
    text-decoration: underline;
    color: var(--dark-blue);
    transform: scale(1.05);
  }


/*HOME.TSX*/
.contact-us-background {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-image: url('../logos/WhiteBackground.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.contact-us-container {
  width: 600px;
  background-color: white;
  border: 2px solid var(--dark-blue);
  padding: 40px;
  border-radius: 10px;
  box-shadow: 2px 8px 16px rgba(0, 0, 0, 0.5);
  margin-top: 50px;
  margin-bottom: 50px;


}

.contact-get-in-touch-text {
  display: flex;
  flex-direction: column;
  color: var(--dark-blue);
  text-decoration: underline;
  font-size: 1.5rem;
  margin-top: 0;
}

.contact-get-in-touch-text-para {
  font-size: 1rem;
  color: var(--light-blue);
  line-height: 1.5;
}

.contact-card-container {
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.custom-input {
  width: 100%;
  padding: 10px;
  font-size: 0.8rem;
  border-radius: 5px;
  border: 3px solid var(--dark-blue);
  background-color: var(--light-blue);
  color: var(--pure-white);
  margin-bottom: 10px;
}
.custom-input::placeholder {
  color: var(--pure-white);
}

.contact-button-submit{
  background-color: var(--dark-blue);
  width: 110%;
  padding: 5px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  color: var(--pure-white);
  font-weight: bold;
}

.contact-button-submit:hover {
  background-color: var(--light-blue);
  transform: scale(1.03);
}

.contact-icon-wrapper-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: -15px;
  margin-left: 20%;
}


/*-----------------------------------------FEATURES.TSX ----------------------------------------------*/
.features-customer-support {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 2rem 1rem;
  background-color: var(--pure-white);
  padding: 10px;
  padding-left: 70px;
  padding-right: 70px;
}

.features-customer-support h1 {
  font-size: 2rem;
  margin-bottom: 1rem;
  color: var(--dark-blue);
  text-decoration: underline;
}

.features-customer-support p {
  font-size: 1.15rem;
  margin-bottom: 2rem;
  color: var(--light-blue);
}

.features-customer-support-contrast {
  background-image: url('../logos/WhiteBackground.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  justify-content: space-between;
  align-items: space-between;
  padding: 20px;
  padding-left: 70px;
  padding-right: 70px;
  flex-direction: row-reverse;
}

/*BOT PLAN CARD PAYMENT STUFF*/
.orgadmin-card-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 14px;
  background-color: var(--pure-white);
  border-radius: 20px;
  border: 4px solid var(--light-blue);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
  margin-left:50px;
  margin-right: 50px;
}

.orgadmin-card-information {
  font-weight: bold;
  font-size: 1.8rem;
  text-align: start;
  color: var(--dark-blue);
  margin: 0;
}

.orgadmin-card-information-inner-text {
  color: var(--light-blue);
}

.orgadmin-card-information, .orgadmin-card-information-inner-text {
  margin: 0;
  padding: 0;
}




/*====================================TRANSACTIONHISTORYTABLE.TSX==========================================*/
/* TransactionHistoryTable.css */

.transaction-history-table {
  width: 100%;
  border-collapse: collapse;
  font-family: Arial, sans-serif;
}

.transaction-history-table th,
.transaction-history-table td {
  border: 1px solid var(--light-blue);
  padding: 8px;
  text-align: center;
  color: var(--dark-blue);
}

.transaction-history-table th {
  background-color: #f2f2f2;
  color: #333;
  font-weight: bold;
}

.transaction-history-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.transaction-history-table tr:hover {
  background-color: #ddd;
}


.transaction-history-table .invalid-date {
  color: #ff0000;
  font-style: italic;
}

.empty-message {
  text-align: center;
  padding: 20px;
  color: #888;
}

.transaction-history-table th {
  background-color: var(--light-blue);
  color: var(--pure-white);
  border: 1px solid var(--pure-white);
}

.bot-plan-card-selected-plan {
  background-color: var(--dark-blue);
  border: 1.4px solid var(--pure-white);
}

.my-botplan-card {
  cursor:  pointer !important;
}

.home-header-responsive-menu {
 display: none;   
}

.botplancarousel-home-responsive {
  display: none;
}

.home-hero-see-features-button {
  display: none;
}

.home-responsive-nav {
  display: none;
}

.responsive-h1-hide {
  display: none;
}

.only-show-on-small-devices {
  display: none;
}

/*==========================================MEDIA RESPONSIVENESS=============================================*/
@media screen and (max-width: 983px) {
  *{
    font-size: 10px;
  
  }

  .only-show-on-small-devices {
    display: block;
  }

  .signup-container {
    display: none;
  }

  .login-container {
    display: none;
  }
  
  .contact-icon-wrapper-container {
    margin-left: 0;
  }

  .bot-plan-card {
    min-width: 30%;
    max-width: 30%;
  }

  .home-logo img {
    height: 30px;
  }

  .home-hero-image img { 
    height: 70% !important;
    bottom: 50px;
  }

  .home-header-responsive-menu {
    display: none; 
   }

}

@media screen and (max-width: 772px) {

  .responsive-h1-hide {
    display: block;
  }

  .responsive-h1-show {
    display: none;
  }

  
  .home-hero{
    position: relative;
    background-image: url('../logos/fogResponsive.gif') !important;
    background-size: cover !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
}

.home-hero::before {
  content: '';
  position: absolute !important;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(85, 112, 138, 0.6) !important; /* Using --dark-blue with 70% opacity */
  background: linear-gradient(180deg,rgba(0, 22, 42, 0.86),rgba(85, 173, 255, 0.3)) !important;
}
  

  .features-customer-support {
    flex-direction: column;
    text-align: center;
  }

  .features-customer-support div p, .features-customer-support div h1 {
    text-align: center !important;
  }
  .features-customer-support {
    padding: 5px;
  }

  .home-get-in-touch button {
    font-size: 0.8rem;
    min-width: 25%;
  }

  .contact-page-email-div {
    min-width: 30px;
    margin-top: 15px;
  }

  .contact-page-email-text {
    margin-top: 15px;
  }
  
  

  .home-icon-wrapper svg {
    width: 40px;
    height: 40px;
  }

  .features-img {
    width: 100%;
    height: auto;
  }

  .botplancarousel-home-normal {
    display: none;
  }

  .botplancarousel-home-responsive {
    display: block;
  }

  .home-business-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .home-business-feature {
    max-width: 80%;
  }

  .home-business, .home-choose-us-container {
    padding: 1.5rem 20px;
  }

  .home-choose-us-container {
    display: flex;
    flex-direction: column;
  }

  .home-choose-us-text-container {
    max-width: 100%;
  }

  .home-business h2, .home-choose-us-text-container h2 b,  .features-customer-support h1, .home-get-in-touch-text h2, .home-choose-us-text-container h2, .home-steps h2, .home-botplans h2 {
    font-size: 1.7rem;
    text-align: center;
  }
  .home-get-in-touch {
    width: 100% !important;
  }
  .home-get-in-touch-text h2 {
    margin-right: 20px;
  }

  .contact-card-container {
    flex-direction: column;
  }

  .contact-us-page-image-writing {
    display: none;
  }

  .contact-us-page-form {
    width: 95% !important;
  }
  .home-get-in-touch-text p {
    margin-right: 20px;
  }

  .home-responsive-nav {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    position: fixed;
    padding: 10px 0;
    z-index: 9999;
    top: 0;
    background-color: var(--light-blue);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    width: 100vw;
    height: 100vh;
  }

  .home-get-in-touch button {
    margin-left: 20px;
  }

  .carousel-container button {
    margin-left: 8px;
    margin-right: 8px;
    padding: 3px 8px;
    font-size: 1rem;
  }

  .bot-plan-card {
   margin: 0 3px;
   min-width: 130px;
   max-width: 40%;
  }

  .carousel-content {
    width: auto;
  }

  .carousel-container {
    min-width: auto !important;
    max-width: none;
  }

  .home-hero-try-now-button {
    display: none;
  }

  .home-hero-see-features-button {
    display: block;
  }

  .home-get-in-touch-signup-button {
    display: none;
  }

  .home-get-in-touch {
    display: flex;
    margin-left: 0;
    justify-content: space-between;
    align-content: space-between;
    align-content: space-between;
    width: calc(100% - 10px);
    padding: 0;
    margin-right: 10px;
    margin-bottom: 10px;
  }

  .home-footer-nav {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .home-footer-link-signin {
    display: none;
  }
  .home-footer-bottom p {
    font-size: 0.8rem;
  }

  .home-steps p, .home-botplans p {
    font-size: 1rem;
  }
  .home-step::before {
    display: none;
  }
  .home-step {
    max-width: 100%;
  }

  .home-icon-wrapper {
    width: 50px;
  }
  
  .step-title, .step-description {
    font-size: 1rem;
  }

  .step-title {
    font-weight: bold;
    color: var(--dark-blue);
    margin-bottom: 0.5rem;
    margin-top: 1rem;
  }

  .carousel {
    min-width: 70%;
  }

  .step-description {
    color: var(--light-blue);
  }

  .home-steps-container {
    flex-direction: column;
  }
  .home-choose-us-text-container h2 {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
  }

  .home-steps{
    padding: 1rem 2rem;
  }

  .home-business button {
    width: 90%;
  }

  .home-header-responsive-menu {
   display: block; 
   color: var(--pure-white);
   font-size: 2.5rem;
   margin: 0;
   margin-top: 8px;
   padding: 0;
   line-height: 1;
    cursor: pointer;
  }

  .home-logo img {
    height: 25px;
    margin-top: 10px;
  }

  .home-nav {
    display: none;
  }

  .home-auth-buttons {
    display: none;
  }

  .home-hero {
    flex-direction: column-reverse;
    height: calc(100vh - 30px);
  }

  .home-hero-image img {
   display: none;
   
  }
  .home-hero-content {
    max-width: 100%;
    display: flex;
    flex-direction: column;
    place-content: center;
    width: calc(100vw - 40px);
  }
  .home-hero-content button {
    width: 100%;
    margin-left: 0;
  }

  .home-hero-content h2 {
    text-align: center;
    align-self: center;
  }

  .home-step-carousel-container {
    display: flex;
  }

  .homestep-carousel-buttons {
    margin-top: 150px;
  }

  .homestep-carousel-buttons button {
    margin: 0;
    padding: 0;
    line-height: 0;
    width: 30px;
    text-align: center;
    height: 30px;
    border-radius: 50%;
    background-color: var(--light-blue);
    color: var(--pure-white);
    font-size: 1.5rem;
    border: none;
    cursor: pointer;
  }

  .home-steps-container {
    display: none;
  }

  .home-hero {
    display: flex;
    place-content: center;
    padding: 0;
  }
  .home-hero-content h2 {
    font-size: 2.4rem !important;
    margin-left: 0;
  }
  .home-hero-content p {
    margin-left: 0px !important;
    text-align: center;
    font-size: 1.1rem !important;
    
  }
}
