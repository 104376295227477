.validate-email-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #779cc0; /* Light blue background */
}

.header-background {
    text-align: center;
    background-color: #fff;
    padding: 2rem;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
}

.header-background h2 {
    font-size: 1.5rem;
    font-weight: bold;
}

.header-background p, .header-background h2 {
    color: #779cc0;
}

.icon-container {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    margin-bottom: 1rem;
}

.envelope-icon {
    width: 48px;
    height: 48px;
    color: #1d4ed8; /* More visible color */
}

.code-input-container {
    display: flex;
    justify-content: center;
    gap: 0.5rem;
    margin-bottom: 1rem;
}

.code-input {
    width: 2rem;
    height: 2rem;
    text-align: center;
    font-size: 1.25rem;
    border: 1px solid #cbd5e0;
    border-radius: 4px;
}

.resend-email {
    margin-bottom: 1rem;
}

.resend-button:disabled {
    color: #cbd5e0;
    cursor: not-allowed;

}
.resend-button {
    background-color: transparent;
    color: #779cc0;
    border: none;
    cursor: pointer;
    align-self: center;
    width: 150px;
    transition: color 0.3s;

}

.verify-button {
    padding: 0.75rem 1.5rem;
    background-color: #779cc0;
    color: white;
    border: none;
    border-radius: 4px;
    width: 300px;
    align-self: center;
    cursor: pointer;
    transition: background-color 0.3s;
}

.verify-button:disabled {
    background-color: #cbd5e0;
    cursor: not-allowed;
}

.validate-email-mail-icon {
    width: 100px;
    height: 100px;
    fill: #779cc0;
}
